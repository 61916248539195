import React , { useEffect , useState  }  from 'react';
import APIService from "../../api.service";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import DownloadComponent from '../download/download'

import './privacy.scss';


function PrivacyComponent(props){

  const dataa = props.setting.data;
  let downloadLink = '';
  let android_download_link = '';
  let apple_download_link = '';

  if(dataa ==  null){
      console.log("null Data")
  }else{
      android_download_link = dataa[0].android_download_link ;
      apple_download_link = dataa[0].ios_download_link ;
      downloadLink = dataa[0].downloadLink ;

  }




  const [error, setError] = useState(null);
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    APIService.getPage('home').then(response => {
      setPageData(response.data.data);
      setError(null);
    }).catch(e => {
      console.log(e);
      setError(e.message);
    });
    


    APIService.getPage('privacy-policy').then(response => {
      setPageData(response.data.data);
      setError(null)
    }).catch(e => {
      setError(e.message)
    });

  }, []);

  if(!pageData){
    return(
      <div className="page privacyPage">
        <div className="container">
          <div className="row">
          <div className='loadingspinner'></div>
          </div>
        </div>
      </div>

    )
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageData.meta_title}</title>
          <meta name="description" content={pageData.meta_description} />
          <meta property="og:title" content={pageData.og_title} />
          <meta property="og:site_name" content={pageData.name} />
          <meta property="og:type" content={pageData.og_type} />
          <meta property="og:image" content={pageData.image} />
          <meta property="og:description" content={pageData.og_description} />
          <meta property="og:url" content={pageData.og_url} />
          <link rel="canonical" href={pageData.canonical_link } />
          <meta property="keywords" content={pageData.meta_title } />
        </Helmet>
      </HelmetProvider>
      <div className="page privacyPage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
                 <h1>Privacy Policy</h1>
                <div dangerouslySetInnerHTML={{__html: pageData.description}}></div>
            </div>
          </div>  
        </div>
      </div>

      <DownloadComponent  AndroidLink={android_download_link} AppleLink={apple_download_link}/>

    </>
  );

};



export default PrivacyComponent;