import React , { useEffect , useState  }  from 'react';
import './gamedetail.scss';
import { useParams } from "react-router-dom";
import fallbackone from '../../assets/fallbackone.jpg';
import { Helmet, HelmetProvider } from 'react-helmet-async';
 
import APIService from "../../api.service";
 
import { Link } from 'react-router-dom';
 

function GamedetailComponent(){
  const [gameData, setGameData] = useState([]);
  
  const [screenshotImages, setScreenshotImages] = useState([])
  const [error, setError] = useState(null);
 
  const { id } = useParams();

 
  useEffect(() => {
    APIService.getGameBySlug(id).then(response => {
      setGameData(response.data.data);
      setScreenshotImages(response.data.data.image.key);
      setError(null)
    }).catch(e => {
      console.log(e);
      setError(e.message)
    });
  }, [id]);
  
  return (
    <>
        <HelmetProvider>

    <Helmet>
      <meta charSet="utf-8" />
      <title>{gameData.name}</title>
      <meta name="description" content={gameData.description} />
      <meta property="og:title" content={gameData.og_title} />
      <meta property="og:site_name" content={gameData.name} />
      <meta property="og:type" content={gameData.og_type} />
      <meta property="og:image" content={`${process.env.REACT_APP_FILE_BASE_URL}/public${screenshotImages}`} />
      <meta property="og:description" content={gameData.og_description} />
      <meta property="og:url" content={gameData.og_url} />
      <link rel="canonical" href={gameData.canonical_link } />
      <meta property="keywords" content={gameData.meta_title } />
    </Helmet>
    </HelmetProvider>


    <div className='page gameDetailPage'> 
      <div className='gameDetailSection'>
        <div className="container">
          <div className="row">
            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
            {gameData !== null ? (<img src={`${process.env.REACT_APP_FILE_BASE_URL}/public${screenshotImages}`} alt={gameData.slug} className='gameDetailImage d-block mx-auto up-down-slow'  /> ) : (<img src={fallbackone} alt="Lorem" className='w-100' />)}                      
            {/* <img src={`${process.env.REACT_APP_FILE_BASE_URL}/public${gameData.image.key}`} alt={gameData.slug} className='w-100'  />  */}
             </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 gameDescriptionBox">
              <div>
                <h1 className='gameTitle'>{gameData.name}</h1>
                <div  className='gameDescription'  dangerouslySetInnerHTML={{__html: gameData.description}}></div>
                <Link to="/register"><button  className='regiterCTA'>Register Now!</button></Link>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};



export default GamedetailComponent;