import './App.scss';
import React , { useEffect , useState  }  from 'react';
import HeaderComponent from './components/header/header';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomepageComponent from './components/homepage/homepage';
import GamePageComponent from './components/games/games';
import FooterComponent from './components/footer/footer';
import ContactComponent from './components/contact/contact';
import RegisterComponent from './components/register/register';
import AboutComponent from './components/about/about';
import PromotionsComponent from './components/promotions/promotions';
import MediaComponent from './components/media/media';
import MediaDetailComponent from './components/media/mediadetail';
import ResponsibleGamingComponent from './components/responsible-gaming/responsiblegaming';
import RedemptionPolicyComponent from './components/redemptionpolicy/redemption-policy';
import SweepRulesComponent from './components/sweep-rules/sweep-rules';
import PrivacyComponent from './components/privacy/privacy';
import FaqsComponent from './components/faqs/faqs';
import TermsComponent from './components/terms/terms';
import StoreComponent from './components/store/store';
import GamedetailComponent from './components/gamedetail/gamedetail';
import NotFound from './components/notfound/notfound';
import SearchComponent from './components/search/search';
import ScrollToTop from './scrolltotop';

import RedirectComponent from './components/redirect/redirect';

// import GameListComponent from './components/gameList/games';



import DataService from "./data.service";


function App() {
  const [settingData, setSettingData] = useState([]);

  useEffect(() => {
    DataService.getContent('settings')
    .then(response => {
        setSettingData(response.data);
    })
    .catch(e => {
        console.log(e);
    });
  }, []);


  return (
    <>    
    <Router >
        <ScrollToTop />
        <HeaderComponent  setting={settingData} />
        <Routes>
              <Route path="/" element={<HomepageComponent setting={settingData} />} />
              <Route path="/games" element={<GamePageComponent  setting={settingData} />} />
              <Route path="/games.html" element={<RedirectComponent  setting={"games"} />} />

              <Route path="/game/:gametype" element={<GamePageComponent />} />
              <Route path="/gamedetail/:id" element={<GamedetailComponent />} />
              {/* <Route path="/gamedetail/:id.html" element={<GamedetailComponent />} /> */}
 
              <Route path="/promotions" element={<PromotionsComponent setting={settingData} />} />
              <Route path="/promotions.html" element={<RedirectComponent  setting={"promotions"} />} />

              <Route path="/media" element={<MediaComponent setting={settingData}/>} />
              <Route path="/media.html" element={<RedirectComponent  setting={"media"} />} />

              <Route path="/media/:id" element={<MediaDetailComponent setting={settingData}/>} />
              <Route path="/contact" element={<ContactComponent setting={settingData} />} />
              <Route path="/register" element={<RegisterComponent setting={settingData} />} />
              <Route path="/register.html" element={<RedirectComponent  setting={"register"} />} />

              <Route path="/about" element={<AboutComponent  setting={settingData} />} />
              <Route path="/about.html" element={<RedirectComponent  setting={"about"} />} />

              <Route path="/faqs" element={<FaqsComponent setting={settingData}   />} />
              <Route path="/faqs.html" element={<RedirectComponent  setting={"faqs"} />} />

              <Route path="/terms" element={<TermsComponent setting={settingData} />} />
              <Route path="/terms.html" element={<RedirectComponent  setting={"terms"} />} />

              <Route path="/store" element={<StoreComponent  />} />
              <Route path="/store.html" element={<RedirectComponent  setting={"store"} />} />

              <Route path="/search/" element={<SearchComponent />} />
              <Route path="/search/:string" element={<SearchComponent />} />
              <Route path="/privacy-policy" element={<PrivacyComponent setting={settingData} />} />
              <Route path="/privacy-policy.html" element={<RedirectComponent  setting={"privacy-policy"} />} />

              <Route path="/responsible-gaming" element={<ResponsibleGamingComponent  setting={settingData}  />} />
              <Route path="/responsible-gaming.html" element={<RedirectComponent  setting={"responsible-gaming"} />} />

              <Route path="/redemption-policy" element={<RedemptionPolicyComponent  setting={settingData}  />} />
              <Route path="/redemption-policy.html" element={<RedirectComponent  setting={"redemption-policy"} />} />

              <Route path="/sweep-rules" element={<SweepRulesComponent />} />
              <Route path="/sweep-rules.html" element={<RedirectComponent  setting={"sweep-rules"} />} />

              <Route path="*"  element={<NotFound />} />
              {/* <Route path="/gamelist" element={<GameListComponent />} />
              <Route path="/gamelist/:id" element={<GameListComponent />} /> */}
        </Routes>
        <FooterComponent setting={settingData} />  
    </Router>
    </>
  );
}

export default App;
