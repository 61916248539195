import React , { useEffect , useState  }  from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './header.scss';
import logo from '../../assets/logo.png';
import Marquee from "react-fast-marquee";
import {  FaChevronUp } from "react-icons/fa";

import famrriches from "../../assets/games/farmriches.jpg";
import legacyofmacchu from "../../assets/games/legacyofmacchu.jpg";
import soccerchampions from "../../assets/games/soccerchampions.jpg";
import powerofscrabes from "../../assets/games/powerofscrabes.jpg"
import mystryofatzes from "../../assets/games/mystryofatzes.jpg"
import pinatacarnival from "../../assets/games/pinatacarnival.jpg"
import lunarfestival from "../../assets/games/lunarfestival.jpg"
import epicroma from "../../assets/games/epicroma.jpg"
import buffalolegion from "../../assets/games/buffalolegion.jpg"
import bloodsucker from "../../assets/games/bloodsucker.jpg"
import goldendust from "../../assets/games/goldendust.jpg"
import jubilantcircus from "../../assets/games/jubilantcircus.jpg"
import retrorodiogame from "../../assets/games/retrorodiogame.jpg"
 
// import React , { useEffect , useState  }  from 'react';
// import {Link} from "react-router-dom";
// import { useNavigate } from 'react-router-dom';


function HeaderComponent(props){
  // {props.setting.facebook_link}
  // const navigate = useNavigate();  
  const location = useLocation();


  const [scroll, setScroll] = useState(false);
    useEffect(() => {
    window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 50);
    });
    }, []);
    


    const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };


  return (
    <>
        
     
    <div   className={`header  ${scroll ? "fixed-top" : " "}`}  >
        <div className="container">
            <div className="row ">
                <div className="headerNav col-lg-12">
                <Navbar  expand="lg" >
                    
                    <Navbar.Brand href="/"> <img src={logo} alt="CosmosSlotLogo" className="navbarLogo"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse   className="justify-content-end">
                      <Nav activeKey={location.pathname} className="me-auto align-items-center">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/games">Games</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                        <Nav.Link href="/promotions">Promotions</Nav.Link>
                        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                          <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        </NavDropdown> */}
                        <Nav.Link href="/media">Media</Nav.Link>
                        <Nav.Link href="/contact">Contact</Nav.Link>
                        <Nav.Link href="/register"  ><button className='registerBtn'>Register</button></Nav.Link>

                      </Nav>
                    </Navbar.Collapse>
                </Navbar>
                </div>
            
            </div>
        </div>

        <div className="marqueeSection">
            <Marquee pauseOnHover={true} speed={80}>
                <div className="marqueeBox">
                    <div><img src={famrriches} alt="" className="w-100" /></div>
                    <div>Bernard D. Becker won 550 Token in <span>Farm Riches</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={legacyofmacchu} alt="" className="w-100" /></div>
                    <div>Grace McMahon won 320 Token in <span>Legacy of Machu Picchu</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={soccerchampions} alt="" className="w-100" /></div>
                    <div>Troy Roberson won 410 Token in  <span>Soccer Champions</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={powerofscrabes} alt="" className="w-100" /></div>
                    <div>Wanda Burch won 480 Token in <span>Power of Scrabes</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={mystryofatzes} alt="" className="w-100" /></div>
                    <div>Jacqueline C. Tamplin won 320 Token in  <span>Mystery of aztec</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={pinatacarnival} alt="" className="w-100" /></div>
                    <div>James M. Burgess won 480 Token in <span>Pinata Carnival</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={lunarfestival} alt="" className="w-100" /></div>
                    <div>Tammy A. Kemp won 220 Token in <span>Lunar Festival</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={epicroma} alt="" className="w-100" /></div>
                    <div>Jessica J. Patton won 620 Token in <span>Epic Roma Legion</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={buffalolegion} alt="" className="w-100" /></div>
                    <div>Bernard D. Becker won 550 Token in <span>Buffalo Legion</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={bloodsucker} alt="BloodSuckerGame" className="w-100" /></div>
                    <div>Jessica J. Patton won 620 Token in  <span>Blood Sucker Game</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={goldendust} alt="" className="w-100" /></div>
                    <div>James M. Burgess won 480 Token in<span>Golden Dust</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={jubilantcircus} alt="" className="w-100" /></div>
                    <div>Pamela Hooten won 420 Token in <span>Jubilant Circus</span></div>
                </div>
                <div className="marqueeBox">
                    <div><img src={retrorodiogame} alt="" className="w-100" /></div>
                    <div>James M. Burgess won 480 Token in<span>Retro Rodeo</span></div>
                </div>

            </Marquee>
        </div>
        
    </div>

    {showButton && (
           <div onClick={scrollToTop} className='back-to-top'><FaChevronUp /></div>
      )}
        
    </>
  );

};



export default HeaderComponent;