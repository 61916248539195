import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import './search.css';
// import GameMenu from '../games/Menu/GameMenu';
import fallbackone from '../../assets/fallbackone.jpg';
import magnify from  '../../assets/magnifyingGlass.svg';



function SearchComponent(props){
    const [gameData, setGameData] = useState([]);
    const [zeroResult, setZeroResult] = useState(true);

    const { string } = useParams();

    const [listView, setlistView] = useState(false);
    const [gridView, setgridView] = useState(true);
    const [dropdownValue, setdropdownValue] = useState(0);

  const listviewfunctions = () => {
    setlistView(true);
    setgridView(false);
  };
  const gridviewfunctions = () => {
    setgridView(true);
    setlistView(false);

  };


  useEffect(() => {
    DataService.getSearch(string)
    .then(response => {
        setGameData(response.data.results);
        if(response.data.count === 0){
          setZeroResult(true)
        }else{
          setZeroResult(false)
        }
    }).catch(e => {
        console.log(e);
    });
},[string]);

 
   return (
    <>  
         <div className='page'>
            <div className="container">
              <div className="gameMenu gameMenuSearch">
                <div>
                  <label className='labelName'>&nbsp;</label>
                  <h3 className='gameMenuHeading' >Search : {string}</h3>
                </div>
              <div>
              {/* <GameMenu gridviewfunction={gridviewfunctions}  listviewfunction={listviewfunctions} /> */}
            </div>
          </div>  
        
          <h2 className={`zeroResult ${zeroResult === true ? "hideZero" : ''}`}><img className='magnifyGlass' src={magnify}></img>Nothing Found</h2>
          <div className={`${gridView === true ? 'd-block ' : 'd-none'}`}>

                  <div className='row'>
                      {gameData.map((item, i) => (
                        <Link className="col-lg-3 col-sm-6 col-12" key={i}  to={`/gamedetail/${item.id}`}>
                          <div className="homepageLatestCard " key={i}>
                            {item.image ? (<img src={item.image} alt="Game_Image" className='w-100'  /> ) : (<img src={fallbackone} alt="Lorem" className='w-100' />)}                      
                            <div className='homepageLatestCardContent'>
                              <div>
                                <h4 className='title'>{item.name}</h4>
                                <p className='cardDescription'> {item.game_type === 1 ? ('Fish' ) : ('')}
                                {item.game_type === 2 ? ('Slot' ) : ('')}
                                {item.game_type === 3 ? ('Keno' ) : ('')}
                                {item.game_type === 4 ? ('Other' ) : ('')}</p>
                              </div>
                              <div>
                                <button className='btn'>Join Now</button>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
          </div>
          <div className={`${listView === true ? 'd-block' : 'd-none'}`}>
                    {/* <GameListCard dataParentToChild = {gameData}/> */}

                    <div id='game_list_card'>
                      {gameData.map((item, i) => (
                        <Link key={i} to='/'>
                          <div className='ListGrid'>
                            <div>
                            {item.image ? (<img src={item.image} alt="Game_Image" className='listImage w-100'  /> ) : (<img src={fallbackone} alt="Lorem" className='listImage w-100' />)}                      
                            </div>
                            <div></div>
                            <div className='GameSection'>
                              <h3 className='ListText'>{item.name}</h3>
                              <p className='ListCategory'> {item.game_type === 1 ? ('Fish' ) : ('')}
                              {item.game_type === 2 ? ('Slot' ) : ('')}
                              {item.game_type === 3 ? ('Keno' ) : ('')}
                              {item.game_type === 4 ? ('Other' ) : ('')}</p>
                              <div className='GameDescriptionBoxTablet'>{item.description}</div>
                            </div>
                            <div className='GameDescriptionBox'>{item.description}</div>
                          </div>
                        </Link>
                      ))}
                    </div>
          </div>
        </div>
            
        </div>
    </>
  );
};



export default SearchComponent;