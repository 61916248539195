import React , { useEffect , useState  }  from 'react';
import APIService from "../../api.service";
import fallbackone from '../../assets/fallbackone.jpg';
import all from '../../assets/all.png';
import {Link} from "react-router-dom";


function GameComponent(){
    const [gamesType, setGamesType] = useState([]);
    const [allgameData, setAllGameData] = useState([]);
    const [gameData, setGameData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        APIService.getGamesType()
        .then(response => {
            setGamesType(response.data.data);
            setError(null)
        }).catch(e => {
            setGameData([])
        });
        APIService.getGames()
        .then(response => {
            setAllGameData(response.data.data);
            setError(null)
            setGameData(response.data.data)
        }).catch(e => {
          setGameData([])
          setError(e.message);
        });
    },[]);
  
    const changeGame = (id) => {
        if(id === 0){
            setGameData(allgameData);
        }else{
            console.log(id)
            setGameData(
                allgameData.filter(gameData  => gameData.game_type.includes(id))  
            );
        }   
    }
    
    return (
    <>
    <div className="gameList w-100">
    <div className="particle particle-1"></div>
        <div className="particle particle-2"></div>
        <div className="particle particle-3"></div>
        <div className="particle particle-4"></div>
        <div className="container">
            <div className="row">
            
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 w-100">
                <div className="row">
                    <ul className='tabLink'>
                        <li  onClick={() => changeGame(0) } className='tabBtn'>
                            <img src={all} />
                            <span className='games-types-span'> All</span>
                        </li>
                        {gamesType.map((item, i) => (
                            <li key={i} onClick={() => changeGame(item.id) } className='tabBtn'>
                                {/* <span>{ item.GameTypes !== null ? item.GameTypes.id : "" }</span> */}
                                {item.image !== null ? (<img src={`${process.env.REACT_APP_FILE_BASE_URL}/public${item.image.key}`} alt={item.slug} className='w-100'  /> ) : ""}                      
                                <span className='games-types-span'>{item.name}</span>
                            </li>
                        ))}
                    </ul> 
                </div>
                <div className="row">       
                {gameData.map((item, i) => (
                    <div key={i} className='cardRow col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-4'>
                        <Link to={`/gamedetail/`+item.slug}>
                            <div className='portfolio-wrap'>
                                <div className="portfoliio-image">
                                    {item.image !== null ? (<img src={`${process.env.REACT_APP_FILE_BASE_URL}/public${item.image.key}`} alt={item.slug} className='w-100'  /> ) : (<img src={fallbackone} alt="Lorem" className='w-100' />)}                      

                                </div>
                                <div className="portfolio-caption">
                                    <h2>{item.name}</h2>
                                    <div className="rounded-button btn-blue"> Info </div>
                                </div>
                            </div>
                            
                        </Link>
                    </div>
                ))}
                </div>
            </div>
            </div>
        </div>
       
    </div>
        
    </>
    );
};

export default GameComponent;