

import React  from 'react';
import {Link} from "react-router-dom";
import './download.scss';
import phone from "../../assets/images/backgrounds/mobile-phone.png"

function DownloadComponent(props) {
  
  return (
  
    <>    
       

       <div className="calltoaction">
       <div className="particle particle-1"></div>
        <div className="particle particle-2"></div>
        <div className="particle particle-3"></div>
        <div className="particle particle-4"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-12"></div>
                    <div className="col-lg-8 col-12">
                        <div className="text-center">
                            <p className='calltoactionText'>Get it now on your phone</p>

 
                            <h2 className='coolHeading text-center'>More Features In Mobile App</h2>
                            {
                                props.AndroidLink !== null ? 
                                <a href={props.AndroidLink} className="download androidDownload"   >
                                <div>
                                <svg width="700px" height="700px" viewBox="0 0 40 48" version="1.1" id="android">
                                <path d="M27.4703377,4.19382178 L29.9390848,0.715960396 C30.0856739,0.509227723 30.0491797,0.237861386 29.8575239,0.109544554 C29.666113,-0.0180594059 29.3915493,0.045029703 29.2460623,0.252 L26.6803239,3.8649505 C24.9894667,3.21873267 23.1118522,2.85861386 21.1328377,2.85861386 C19.1538232,2.85861386 17.2757188,3.21837624 15.5849841,3.8649505 L13.019613,0.251881188 C12.8725341,0.0451485149 12.5984601,-0.0181782178 12.4069268,0.109425743 C12.2153935,0.237386139 12.1790217,0.508871287 12.3256109,0.715841584 L14.7952152,4.19370297 C10.8686594,5.96590099 8.11506159,9.33350495 7.7642029,13.263802 L34.5000029,13.263802 C34.1503688,9.33350495 31.3966486,5.9660198 27.4703377,4.19382178 Z M26.7168181,9.67580198 C25.9002297,9.67580198 25.2381906,9.03338614 25.2381906,8.24067327 C25.2381906,7.44784158 25.9002297,6.80542574 26.7168181,6.80542574 C27.5342638,6.80542574 28.196058,7.44807921 28.196058,8.24067327 C28.196058,9.03326733 27.5340188,9.67580198 26.7168181,9.67580198 Z M15.5475101,9.67580198 C14.7303094,9.67580198 14.0681478,9.03338614 14.0681478,8.24067327 C14.0681478,7.44807921 14.729942,6.80542574 15.5475101,6.80542574 C16.3640986,6.80542574 17.0260152,7.44784158 17.0260152,8.24067327 C17.0261377,9.03326733 16.3640986,9.67580198 15.5475101,9.67580198 Z"
                                id="Shape"></path>
                                <path d="M6.04762826,29.6552079 C6.04762826,31.2532277 4.71228333,32.5487525 3.06502319,32.5487525 L3.06502319,32.5487525 C1.41788551,32.5487525 0.0825405797,31.2532277 0.0825405797,29.6552079 L0.0825405797,17.9512871 C0.0825405797,16.3532673 1.41788551,15.0577426 3.06502319,15.0577426 L3.06502319,15.0577426 C4.71228333,15.0577426 6.04762826,16.3532673 6.04762826,17.9512871 L6.04762826,29.6552079 L6.04762826,29.6552079 Z"
                                id="Shape"></path>
                                <path d="M7.76371304,15.1472079 L7.76371304,36.2856238 C7.76371304,37.5409901 8.81273768,38.5588515 10.1070572,38.5588515 L12.8787797,38.5588515 L12.8787797,45.0630891 C12.8787797,46.6612277 14.2140022,47.9566337 15.8612623,47.9566337 C17.5085225,47.9566337 18.8438674,46.6612277 18.8438674,45.0630891 L18.8438674,38.5588515 L23.4204609,38.5588515 L23.4204609,45.0630891 C23.4204609,46.6612277 24.7555609,47.9566337 26.4029435,47.9566337 C28.0503261,47.9566337 29.3854261,46.6612277 29.3854261,45.0630891 L29.3854261,38.5588515 L32.1571486,38.5588515 C33.4514681,38.5588515 34.5007377,37.5415842 34.5007377,36.2856238 L34.5007377,15.1472079 L7.76371304,15.1472079 L7.76371304,15.1472079 Z"></path>
                                <path d="M36.2167,29.6552079 C36.2167,31.2532277 37.5519225,32.5487525 39.1991826,32.5487525 L39.1991826,32.5487525 C40.8463203,32.5487525 42.1816652,31.2532277 42.1816652,29.6552079 L42.1816652,17.9512871 C42.1816652,16.3532673 40.8463203,15.0577426 39.1991826,15.0577426 L39.1991826,15.0577426 C37.5519225,15.0577426 36.2167,16.3532673 36.2167,17.9512871 L36.2167,29.6552079 L36.2167,29.6552079 Z"></path>
                                </svg>
                                <span>Android</span>
                                </div>
                                </a>  : ""
                            }
                              {
                                props.AppleLink !== null ? 
                                <a href={props.AppleLink} className="download appleDownload"  >
                                <>
                                    <svg width="700px" height="700px" viewBox="0 0 40 48" version="1.1" id="ios">
                                    <path d="M33.3732057,25.4546901 C33.3112919,19.3876023 38.4333971,16.4779415 38.6622967,16.3333801 C35.7842105,12.2154854 31.3010526,11.6513684 29.7033493,11.586152 C25.8879426,11.2084211 22.2585646,13.7825497 20.3221053,13.7825497 C18.390622,13.7825497 15.4025837,11.6420117 12.2382775,11.6982456 C8.07961722,11.7586901 4.24507177,14.0625965 2.10373206,17.7043275 C-2.21712919,25.0352281 0.99722488,35.8957661 5.20851675,41.8413099 C7.26631579,44.751345 9.72009569,48.0203041 12.9418182,47.9035322 C16.0443062,47.7824561 17.217512,45.9405848 20.9685167,45.9405848 C24.7195215,45.9405848 25.7736842,47.9035322 29.0571292,47.8432749 C32.3955981,47.7826433 34.5108134,44.8772865 36.5543541,41.9580819 C38.917512,38.5818012 39.8905263,35.3130292 39.9478469,35.1451696 C39.8736842,35.1121404 33.4377033,32.7013801 33.3732057,25.4546901 L33.3732057,25.4546901 Z"></path>
                                    <path d="M27.2041148,7.65015205 C28.9139713,5.6217076 30.068134,2.80748538 29.7533014,0 C27.2902392,0.0980584795 24.3045933,1.60421053 22.537512,3.62807018 C20.9517703,5.4234386 19.5639234,8.28893567 19.9357895,11.0402807 C22.6854545,11.250152 25.4922488,9.6740117 27.2041148,7.65015205 L27.2041148,7.65015205 Z"></path>
                                    </svg>
                                    <span>iOS</span>
                                    </>
                                </a>: ""
                            }
                              
                        </div>
                       

                        <img src={phone} className='downloadPhone w-100' />
                    </div>
                    <div className="col-lg-2 col-12"></div>

                </div>
            </div>
        </div>     
                    
    </>

  );
}

export default DownloadComponent;
