import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";
import { useNavigate } from 'react-router-dom';

function RedirectComponent(props){
    const navigate = useNavigate();  

    useEffect(() => {
           navigate('/'+props.setting)
      }, []);
    
   return (
    <>  
          
    </>
  );
};



export default RedirectComponent;