import React , { useEffect , useState  }  from 'react';
import APIService from "../../api.service";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './faqs.scss';
import { Accordion,  AccordionItem,  AccordionItemHeading,  AccordionItemButton,  AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import DownloadComponent from '../download/download'
import { Helmet, HelmetProvider } from 'react-helmet-async';

function FaqsComponent(props){
    const dataa = props.setting.data;
  let downloadLink = '';
  let android_download_link = '';
  let apple_download_link = '';

  if(dataa ==  null){
    console.log("null Data")
  }else{
    android_download_link = dataa[0].android_download_link ;
    apple_download_link = dataa[0].ios_download_link ;
    downloadLink = dataa[0].downloadLink ;
  }


    const [faqCategory, setFaqCategory] = useState([])
    const [faqData, setFaqData] = useState([])

    // APIService.getPage('faqs')
    useEffect(() => {
        APIService.getPage('faqs')
        .then(response => {
            console.log(response)
        })
        .catch(e => {
            console.log(e);
        });

        APIService.getFaqCategory()
        .then(response => {
            setFaqCategory(response.data.data)
        })
        .catch(e => {
            console.log(e);
        });


        APIService.getFaq()
        .then(response => {
            setFaqData(response.data.data)
        })
        .catch(e => {
            console.log(e);
        });
    }, []);

    const [error, setError] = useState(null);
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    APIService.getPage('faqs').then(response => {
      setPageData(response.data.data);
      setError(null)
    }).catch(e => {
      setError(e.message)
    });
  }, []);
  if(!pageData){
      return(
        <div className="page termsPage">
          <div className="container">
            <div className="row">
                <div className='loadingspinner'></div>
            </div>
          </div>
        </div>
  
      )
  }

  return (
    <>
    <HelmetProvider>

<Helmet>
  <meta charSet="utf-8" />
  <title>{pageData.meta_title}</title>
  <meta name="description" content={pageData.meta_description} />
  <meta property="og:title" content={pageData.og_title} />
  <meta property="og:site_name" content={pageData.og_title} />
  <meta property="og:type" content={pageData.og_type} />
  <meta property="og:image" content={pageData.image} />
  <meta property="og:description" content={pageData.og_description} />
  <meta property="og:url" content={pageData.og_url} />
  <link rel="canonical" href={pageData.canonical_link } />
  <meta property="keywords" content={pageData.meta_title } />

</Helmet>
</HelmetProvider>
        <div className="faqPage page">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        
                        <div  className="tabsContainer">
                            <h4 className='faqsHeading'>FAQ's</h4>
                            <Tabs> 
                                <TabList className="tabheader">    
                                    {faqCategory.map((item, i) => (
                                        <Tab key={i}>
                                            <img src={`${process.env.REACT_APP_FILE_BASE_URL}/public${item.image.key}`} />
                                            {item.title}
                                        
                                        </Tab>
                                    ))}
                                </TabList>
                                {faqCategory.map((item, i) => (
                                    <TabPanel key={i}>
                                        <Accordion  allowMultipleExpanded={true} allowZeroExpanded={true} >
                                        {faqData.filter(faqData  => faqData.FAQCategoryId.includes(item.id)).map((item, i) => (
                                            <AccordionItem  >
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {item.title}
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        ))}
                                        </Accordion>
                                    </TabPanel>
                                ))}
                            </Tabs>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
        <DownloadComponent  AndroidLink={android_download_link} AppleLink={apple_download_link}/>

    </>
  );

};



export default FaqsComponent;