import React , { useEffect , useState  }  from 'react';
import FormComponent from '../form/forms';
import DataService from "../../data.service";


import './store.css';


function StoreComponent(){

    
  return (
    <>
    <div className="page storebg">
      <div className="container">
        <div className="col-12">
          <h3  className="pageBoxTitle text-center">APPLICATION FORM FOR DISTRIBUTORS STORES </h3>
          <p>Skill Gaming Solutionsis where it all happens! An online fish game store, our app provides you a platform where you can distribute and make money from the comfort of your home.</p>
         
                <FormComponent />
             
        </div>
      </div>
    
    </div>
    </>
  );

};



export default StoreComponent;