import React , { useEffect , useState  }  from 'react';
import APIService from "../../api.service";
import notfoundImage from '../../assets/404.png';
import aboutone from '../../assets/images/Banners/about-us-01.jpeg';
import abouttwo from '../../assets/images/Banners/about-us-02.jpeg'

 import DownloadComponent from '../download/download'
import { Helmet, HelmetProvider } from 'react-helmet-async';

import './about.scss';
 
function AboutComponent(props){
  const data = props.setting.data;
    let downloadLink = '';
    let android_download_link = '';
    let apple_download_link = '';


    if(data ==  null){
      console.log("null Data")
  }else{
      android_download_link = data[0].android_download_link ;
      apple_download_link = data[0].ios_download_link ;
      downloadLink = data[0].downloadLink ;

  }


  const [error, setError] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [metaBox, setMetaBox] = useState([]);

  const [sectionBox, setSectionBox] = useState([]);

  
  useEffect(() => {
    APIService.getPage('about-us').then(response => {
      setPageData(response.data.data);
      setMetaBox(response.data.data.meta_box[0]); 
      setSectionBox(response.data.data.sections[0]);

      setError(null)
    }).catch(e => {
      console.log(e);
      setError(e.message)
    });   
  }, []);


  
  if(!pageData){
    return(
      <div className="page termsPage">
        <div className="container">
          <div className="row">
            <div className='loadingspinner'></div>
          </div>
        </div>
      </div>

    )
  }


  
  return (
    <>
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageData.meta_title}</title>
        <meta name="description" content={pageData.meta_description} />
        <meta property="og:title" content={pageData.og_title} />
        <meta property="og:site_name" content={pageData.og_title} />
        <meta property="og:type" content={pageData.og_type} />
        <meta property="og:image" content={pageData.image} />
        <meta property="og:description" content={pageData.og_description} />
        <meta property="og:url" content={pageData.og_url} />
        <link rel="canonical" href={pageData.canonical_link } />
        <meta property="keywords" content={pageData.meta_title } />

      </Helmet>
      </HelmetProvider>
      <div className="page aboutpage">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
              {error !== null ? (<><img src={notfoundImage} className='fourofourImage'/><br></br><h4 className='fourofourText'>Something went wrong</h4></>) : ""}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-12">
                <h1 className='mb-5 coolHeading text-center'>Welcome to Cosmos Slot</h1>
                
               </div>

            </div>
            <div className="row align-items-center">
              <div className='col-lg-5 col-12'>
                  {metaBox  !== null ? <img src={abouttwo} className='w-100 aboutSideImage'  />  : ""}  
              </div>
              <div className="col-lg-7 col-12">
                {sectionBox !== null ? <div dangerouslySetInnerHTML={{__html: sectionBox.paraone}}></div> : ""} 
              </div>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-lg-7 col-12">
                {sectionBox !== null ? <div dangerouslySetInnerHTML={{__html: sectionBox.paratwo}}></div> : ""} 
              </div>
              <div className="col-lg-5 col-12">
                {metaBox  !== null ? <img src={aboutone} className='w-100 aboutSideImage'  />  : ""}
              </div>
            </div>
          </div>
      </div>


      <DownloadComponent  AndroidLink={android_download_link} AppleLink={apple_download_link}/>

    </>
  );

};



export default AboutComponent;