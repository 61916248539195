import http from "./http-common";

class APIService {
  getGames() {
    return http.get(`/game/`);
  }
  getGamesType() {
    return http.get(`/game-type/`);
  }
  getGamesByType(type) {
    return http.get(`/game/?game_type=${type}`);
  }
  getGameBySlug(slug){
    return http.get(`/game/${slug}`);
  }

  getPage(slug){
    return http.get(`/pages/${slug}`);
  }


  getPromotions(item){
    return http.get(`/promotions?itemNo=${item}`);
  }
  getMedia( ){
    return http.get(`/medias`);
  }
  getMediaDetail(slug){
    return http.get(`/medias/${slug}`);
  }


  getFaqCategory(){
    return http.get(`/faq-category`);
  }
  getFaq(){
    return http.get(`/faq`);
  }
  contactus(data) {
    return http.post("/contact-us/", data);
  };
}

export default new APIService();