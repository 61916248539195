import React , {  useEffect , useRef ,useState  }  from 'react';
import { useForm } from "react-hook-form";
import DataService from "../../data.service";
import messageSent from '../../assets/sent.png';
import emailError from '../../assets/emailerror.png';


import Modal from 'react-bootstrap/Modal';
import './forms.scss';
import ReCAPTCHA from "react-google-recaptcha";

function RegisterFormComponent(){
  const recaptchaRef = useRef();
  const [token, setToken] = React.useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsError, setError] = React.useState(false);
  const [ ErrorMsg, setErrorMsg] = React.useState(false);

  const handleRecaptchaChange = (token) => {
    console.log("Recaptcha token:", token);
    setRecaptchaToken(token);
  };


    function openModal() { setIsOpen(true) }
    function closeModal() { setIsOpen(false); }
    function closeErrModal() { setError(false); }
 
    const { register,watch,  getValues, formState: { errors }, handleSubmit } = useForm();
    const onSubmit = data => {
   
      const recaptchaRef = window.grecaptcha;
  
      recaptchaRef.ready(() => {
        recaptchaRef.execute('6LfzZsgkAAAAACiDdo3ouHNXe3EXb3xJg7JRullC', { action: "register_form" }).then((token) => {
          setRecaptchaToken(token);
 
          data = { ...data, reCaptchaToken: token};

   
          DataService.register(data).then(response => {   
            setIsOpen(true)
           }).catch(e => {
            setError(true)
            setErrorMsg(e.response.data.message)
           });
        });
      });

    
    };
     
    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=6LfzZsgkAAAAACiDdo3ouHNXe3EXb3xJg7JRullC";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }, []);
    // useEffect(() => {
    
    // const recaptchaRef = window.grecaptcha;
  
    // recaptchaRef.ready(() => {
    //   recaptchaRef.execute('6LfzZsgkAAAAACiDdo3ouHNXe3EXb3xJg7JRullC', { action: "register_form" }).then((token) => {
    //     console.log("Recaptcha token:", token);

      
    //   });
    // });
    // }, []);

    // const handleSubmitsss = (event) => {
    //   event.preventDefault();
  
    //   const recaptchaRef = window.grecaptcha;
  
    //   recaptchaRef.ready(() => {
    //     recaptchaRef.execute('6LfzZsgkAAAAACiDdo3ouHNXe3EXb3xJg7JRullC', { action: "register_form" }).then((token) => {
    //       console.log("Recaptcha token:", token);
  
        
    //     });
    //   });
    // };
  
  return (
    <>
        <Modal show={modalIsOpen} onHide={closeModal}>
          <Modal.Body>
              <img src={messageSent} className='messageSent'/>
              <h3 className='popupmessage text-center mt-4 mb-4'>Your message has been send successfully</h3>
              <button onClick={closeModal} className='d-block mx-auto btn btn-danger'>Back to Site</button>
          </Modal.Body>
        </Modal>
        <Modal show={modalIsError} onHide={closeModal}>
          <Modal.Body>
              <img src={emailError} className='messageSent'/>
              <h3 className='popupmessage text-center mt-4 mb-4'>Something Went Wrong</h3>
              <p className='text-center'>{ErrorMsg}</p>
              <button onClick={closeErrModal} className='d-block mx-auto btn btn-danger'>Back to Site</button>
          </Modal.Body>
        </Modal>
        
        <div>
          {/* <button onClick={handleSubmitsss}>asdasd</button> */}
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* onSubmit={e => e.preventDefault()} */ /* ⚠ */}
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label>First Name</label>
                      <input className="form-control" {...register("first_name", { required: 'First Name is required', maxLength: { value: 20, message: "Maximum 20 character", } , pattern: { value: /^[A-Za-z]+$/i, message: "Please Enter Valid First Name"}  }) } type="text" placeholder="First Name" />
                      <small id="emailHelp" className="errormsg">{errors.first_name? errors.first_name.message : null}</small>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input className="form-control" {...register("last_name", { required: 'Last Name is required', maxLength: { value: 20, message: "Maximum 20 character", } , pattern: { value: /^[A-Za-z]+$/i, message: "Please Enter Valid Last Name"}  }) } type="text" placeholder="Last Name" />
                      <small id="emailHelp" className="errormsg">{errors.last_name? errors.last_name.message : null}</small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input className="form-control" {...register("email", { required: 'Email is required' , pattern: { value:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "Please Enter Valid Email"}  }) } type="text" placeholder="Email" />
                      <small id="emailHelp" className="errormsg">{errors.email? errors.email.message : null}</small>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label>Is Promoted</label>
                      <select {...register("isPromoted")} name="isPromoted" id="promotedInput" className="form-control inputStyle">
                        <option value={true}>Yes</option>
                        <option  value={false}>No</option>
                      </select>
                    </div>
                  </div>  
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label>Password</label>
                        <input className="form-control" {...register("password", { required: 'Password is required'  , pattern: { value:  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:"<>?,./;'[\]\\=-])[a-zA-Z\d!@#$%^&*()_+{}|:"<>?,./;'[\]\\=-]{8,}$/ , message: "Password must contains 1 uppercase, 1 lowercase 1 number and 1 special character with min 8 characters"}}) } type="password" placeholder="Password" /> 

                      {/* <input className="form-control" {...register("password", { required: 'Password is required'  }) } type="password" placeholder="Password" /> */}
                      <small id="emailHelp" className="errormsg">{errors.password? errors.password.message : null}</small>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input className="form-control" {...register("confirm_password", { required: 'Confirm Password is required'}) } type="password" placeholder="Confirm Password" />
                      {watch("confirm_password") !== watch("password") && getValues("confirm_password") ? ( <small className='errormsg'>Password not match</small> ) : null} 
                    </div>
                  </div>
                </div>               
                <div className="form-group">
                  <label>Invite Code</label>
                  <input className="form-control" {...register("invite_code", { required: 'Invite Code is required'     }) } type="text" placeholder="Last Name" />

                   <small id="emailHelp" className="errormsg">{errors.invite_code? errors.invite_code.message : null}</small>
                </div>
                <ReCAPTCHA
                  sitekey="6LfzZsgkAAAAACiDdo3ouHNXe3EXb3xJg7JRullC"
                  size="invisible"
                  onChange={handleRecaptchaChange}
/>
                  
                 <input type="submit"  className='formSubmitButton'/>
             </form>
        </div>
    </>
  );

};



export default RegisterFormComponent;