import React , { useEffect , useState  }  from 'react';
import {Link} from "react-router-dom";
import footerLogo from '../../assets/logo.png';
import DataService from "../../data.service";
import { FaFacebookSquare , FaInstagramSquare , FaTelegram , FaTwitterSquare , FaYoutube} from "react-icons/fa";


import skrill from '../../assets/img/payment/skrill-icon.png';
import bank from '../../assets/img/payment/bank-logo.png';
import cashapp from '../../assets/img/payment/cashapp-logo.png';

 


import './footer.scss';


function FooterComponent(props){
    const data = props.setting.data;

    let email_link = '';
    let phone_link = '';

    if(data ==  null){
        console.log("")
    }else{
        email_link = data[0].email ;
        phone_link = data[0].phone ;
    }

  return (
    <>  
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-12">
                        <img alt="HighRoller_Logo" src={footerLogo} className="footerLogo" />
                        <p className='footerText'><span>Phone:</span><a href={`tel: ${phone_link}`}>{phone_link}</a></p>
                        <p className='footerText'><span>Email:</span> <a href={`mailto: ${phone_link}`}>{email_link}</a></p>
                     </div>
                    
                    <div className="col-lg-4 col-12">
                        <h3 className='mb-2 pb-2 '>Our Games</h3>

                        <ul className="page-menu">
                            <li><Link to="/media">Media</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/terms">Terms &amp; Conditions</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/faqs">FAQ'S</Link></li>
                            <li><Link to="/responsible-gaming">Responsible Gaming</Link></li>
                            <li><Link to="/sweep-rules">Sweep Rules</Link></li>
                            <li><Link to="/redemption-policy">Redemption Policy</Link></li>
                        </ul>


                        {/* <ul className='footerList'>
                            <li><Link  to="/about">About Us</Link></li>
                            <li><Link  to="/faqs">FAQ's</Link></li>
                            <li><Link  to="/terms">Terms and Conditions</Link></li>
                            <li><Link  to="/privacy">Privacy Policy</Link></li>
                        </ul> */}
                    </div>
                    <div className="col-lg-3 col-12">
                        <h3>Get in Touch</h3>
                        <ul className="page-menu payment-menu">
                            <li><img src={skrill} loading="lazy" decoding="async" alt="Skrill" /></li>
                            <li><img src={bank} loading="lazy" decoding="async" alt="Bank" /></li>
                            <li><img src={cashapp} loading="lazy" decoding="async" alt="Cash App"/></li>
                        </ul>   
                        {/* <div className='socialMedia'>
                        <a  target="_blank" rel="noreferrer"  href={props.setting.facebook_link}><FaFacebookSquare /></a>
                        <a target="_blank" rel="noreferrer" href={props.setting.youtube_channel}><FaYoutube /></a>
                        <a target="_blank" rel="noreferrer" href={props.setting.youtube_channel}><FaTwitterSquare /></a>
                        <a target="_blank" rel="noreferrer" href={props.setting.youtube_channel}><FaTelegram /></a>
                        <a target="_blank" rel="noreferrer" href={props.setting.youtube_channel}><FaInstagramSquare /></a>

                        

                        </div>
                       */}
                    </div>
                </div>
            </div>
        </div>
        <div className="copyrightFooter">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className='text-center'>{props.setting.copyright_text}</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  );

};



export default FooterComponent;