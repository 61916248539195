import React , { useEffect , useState  }  from 'react';
import './homepage.scss';
import {Link} from "react-router-dom";
import APIService from "../../api.service";
import bannerLeftImage from "../../assets/images/backgrounds/game-img-04.png";
import bannerRightImage from "../../assets/images/backgrounds/game-img-01.png";
import logo from '../../assets/img/logo.png'
import { FaCheck } from "react-icons/fa";
import GameComponent from '../games/gameList';
import PromotionHomeComponent from './promotions';
import DownloadComponent from '../download/download'
import {Helmet} from "react-helmet";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import SwiperCore, { Autoplay,Navigation} from 'swiper';
SwiperCore.use([Autoplay,Navigation]);






function HomepageComponent(props){
    const [pageData, setPageData] = useState(null);

    const [error, setError] = useState(null);

    useEffect(() => {
        APIService.getPage('home').then(response => {
          setPageData(response.data.data);
        
          setError(null)
        }).catch(e => {
          console.log(e);
          setError(e.message)
        });   
      }, []);
    const data = props.setting.data;
    let downloadLink = '';
    let android_download_link = '';
    let apple_download_link = '';

    if(data ==  null){
        console.log("null Data")
    }else{
        android_download_link = data[0].android_download_link ;
        apple_download_link = data[0].ios_download_link ;
        downloadLink = data[0].downloadLink ;

    }

    // const [gameData, setGameData] = useState([]);
    // useEffect(() => {
    //     DataService.getAll('latest',4)
    //     .then(response => {
    //         setGameData(response.data.data);
    //     }).catch(e => {
    //         console.log(e);
    //     });
    // }, []);

  
  
    return (
      <>
      {pageData ?  <Helmet>
        <meta charSet="utf-8" />
        <title>{pageData.meta_title}</title>
        <meta name="description" content={pageData.meta_description} />
        <meta property="og:title" content={pageData.og_title} />
        <meta property="og:site_name" content={pageData.og_title} />
        <meta property="og:type" content={pageData.og_type} />
        <meta property="og:image" content={pageData.image} />
        <meta property="og:description" content={pageData.og_description} />
        <meta property="og:url" content={pageData.og_url} />
        <link rel="canonical" href={pageData.canonical_link } />
        <meta property="keywords" content={pageData.meta_title } />

      </Helmet> :  ""}
     
        {/* <div className="bannerSection">
            <Swiper  autoplay={{"delay": 2500,"disableOnInteraction": false }}  loop={true} navigation={true} slidesPerView={1}>
                {gameData.slice(0, 3).map((item, i) => (
                    <SwiperSlide>
                        <div>
                            <div className="row" style={{alignItems : "center"}}>
                                <div className="col-lg-12 col-12">
                                    <img src={item.image} className="w-100" alt="background_Image"/>
                                    <p className="bannerType"> {item.game_type === 1 ? ('Fish' ) : ('')}
                                        {item.game_type === 2 ? ('Slot' ) : ('')}
                                        {item.game_type === 3 ? ('Keno' ) : ('')}
                                        {item.game_type === 4 ? ('Other' ) : ('')}</p>
                                    <h2 className="bannerHeading">{item.name}</h2>
                                    <a target="_blank"  href="#"><button className="bannerBtn">Play Now</button></a>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
         */}

        <div className="home-hero" >
        <div className="particle particle-1"></div>
        <div className="particle particle-2"></div>
        <div className="particle particle-3"></div>
        <div className="particle particle-4"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-12 col-12 up-down-slow ">
                        <img className="w-100 d-block mx-auto"  src={bannerLeftImage} />
                    </div>
                    <div className="col-md-6 pt-3 pt-lg-0 content text-over-layout">
                        <div className="media-slide-content">
                            <h1 className='heading'>Welcome to the CosmoSlots bonus</h1>
                            <p className='description'>Let's play the most Entertaining & Engaging Online Social Casino Games</p>
                            <Link to="register" className="registerBtn" >Download App Now</Link>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-12 up-down ">
                        <img src={bannerRightImage} className="w-100 d-none d-sm-block" />
                    </div>
                </div>
            </div>
        </div>
        

        <GameComponent />


        <div className="playerguide">
        <div className="particle particle-1"></div>
        <div className="particle particle-2"></div>
        <div className="particle particle-3"></div>
        <div className="particle particle-4"></div>
            <div className="container">
                <div className="row align-items-center" >
                    <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                        <h3 className='heading'>Player Guide</h3>
                        <ul>
                            <li><FaCheck /> Gear up your gaming mood with an online casino gaming guide.</li>
                            <li><FaCheck /> Complete the registration process at the CosmoSlots casino site.</li>
                            <li><FaCheck /> Log in to your gaming account.</li>
                            <li><FaCheck /> Go to the Casino Lobby.</li>
                            <li><FaCheck /> Navigate to the "Play Now" tab on the taskbar.</li>
                            <li><FaCheck />  You gain access to live casino games on our gaming platform.</li>
                            <li><FaCheck /> Start playing your game in a fun and gaming mood.</li>
                            <li><FaCheck /> The games can be played on the web browser, mobile, iPad, Mac book, and Laptops.</li>
                        </ul>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <img src={logo}    className="w-100 up-down-slow"/>
                    </div>
                </div>
            </div>
        </div>
        

        <PromotionHomeComponent />
         <DownloadComponent  AndroidLink={android_download_link} AppleLink={apple_download_link}/>
      </>
    );
};

export default HomepageComponent;