import React , { useEffect , useState  }  from 'react';
import { useParams } from "react-router-dom";
import fallbackone from '../../assets/fallbackone.jpg';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import APIService from "../../api.service";
import { Link } from 'react-router-dom';
import DownloadComponent from '../download/download'


function MediaDetailComponent(props){

  const data = props.setting.data;
    let downloadLink = '';
    let android_download_link = '';
    let apple_download_link = '';


  if(data ==  null){
    console.log("null Data")
  }else{
      android_download_link = data[0].android_download_link ;
      apple_download_link = data[0].ios_download_link ;
      downloadLink = data[0].downloadLink ;

  }


  const [gameData, setGameData] = useState([]);
  const [screenshotImages, setScreenshotImages] = useState([])

  const [error, setError] = useState(null);
  const { id } = useParams();

 
  useEffect(() => {
    APIService.getMediaDetail(id).then(response => {
      setGameData(response.data.data);
      setScreenshotImages(response.data.data.image.key);
      setError(null)
    }).catch(e => {
      console.log(e);
      setError(e.message)
    });
  }, [id]);
  
  return (
    <>
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{gameData.name}</title>
        <meta name="description" content={gameData.description} />
        <meta property="og:title" content={gameData.og_title} />
        <meta property="og:site_name" content={gameData.name} />
        <meta property="og:type" content={gameData.og_type} />
        <meta property="og:image" content={gameData.image} />
        <meta property="og:description" content={gameData.og_description} />
        <meta property="og:url" content={gameData.og_url} />
        <link rel="canonical" href={gameData.canonical_link } />
        <meta property="keywords" content={gameData.meta_title } />
      </Helmet>
    </HelmetProvider>

    <div className='page gameDetailPage'> 
      <div className='gameDetailSection'>
        <div className="container">
          <div className="row">
            <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                {screenshotImages !== null   ? (<img src={`${process.env.REACT_APP_FILE_BASE_URL}/public/${screenshotImages}`} alt={gameData.slug} className='gameDetailImage d-block mx-auto  '  /> ) : ""}  
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 gameDescriptionBox">
              <div>
                <h1 className='gameTitle'>{gameData.name}</h1>
                <div  className='gameDescription'  dangerouslySetInnerHTML={{__html: gameData.description}}></div>
               </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>

    <DownloadComponent  AndroidLink={android_download_link} AppleLink={apple_download_link}/>

    </>
  );
};



export default MediaDetailComponent;