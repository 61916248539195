import React , { useEffect , useState  }  from 'react';
import APIService from "../../api.service";
import notfoundImage from '../../assets/404.png'
import { Link } from 'react-router-dom';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import './media.scss';
 import DownloadComponent from '../download/download'

function MediaComponent(props){
    const dataa = props.setting.data;
    let downloadLink = '';
    let android_download_link = '';
    let apple_download_link = '';

    if(dataa ==  null){
        console.log("null Data")
    }else{
        android_download_link = dataa[0].android_download_link ;
        apple_download_link = dataa[0].ios_download_link ;
        downloadLink = dataa[0].downloadLink ;

    }

    const [data, setData] = useState([]);

    useEffect(() => {
        APIService.getMedia()
        .then(response => {
            setData(response.data.data);
        })
        .catch(e => {
            console.log(e);
        });
    }, []);


    const [error, setError] = useState(null);
    const [pageData, setPageData] = useState(null);
    useEffect(() => {
        APIService.getPage('media').then(response => {
        setPageData(response.data.data);
        setError(null)
        }).catch(e => {
        setError(e.message)
        });
    }, []);
    
  return (
    <>
<HelmetProvider>
      {pageData ?  <Helmet>
        <meta charSet="utf-8" />
        <title>{pageData.meta_title}</title>
        <meta name="description" content={pageData.meta_description} />
        <meta property="og:title" content={pageData.og_title} />
        <meta property="og:site_name" content={pageData.og_title} />
        <meta property="og:type" content={pageData.og_type} />
        <meta property="og:image" content={pageData.image} />
        <meta property="og:description" content={pageData.og_description} />
        <meta property="og:url" content={pageData.og_url} />
        <link rel="canonical" href={pageData.canonical_link } />
        <meta property="keywords" content={pageData.meta_title } />

      </Helmet> : ""}
      </HelmetProvider>
        <div className="mediaPage">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <h1 className='coolHeading text-center'>Media</h1>
                     </div>
                </div>
                <div className="row">
                {data.map((item, i) => (
                    <Link key={i} to={`/media/${item.slug}`}>
                    <div className="col-lg-12 col-md-12 col-12 col-12">
                        <div className="row newsbox mb-3 align-items-center">
                            <div className="col-lg-3 col-12">
                                {item.image !== null && item.image.key !== null ? (<img src={`${process.env.REACT_APP_FILE_BASE_URL}/public${item.image.key}`} alt={item.slug} className='w-100'  /> ) : ""}                      
                            </div>
                            <div className={`${item.image !== null && item.image.key !== null ? "col-lg-9" : "col-lg-12"} col-12`}>
                                <h5 className='mediaTitle'>{item.name}</h5>
                                <div className='newsboxDescription' dangerouslySetInnerHTML={{__html:  item.description }}></div>
                            </div>
                        </div>
                    </div>
                    </Link>
                ))}
                </div>
            </div>
        </div>
        <DownloadComponent  AndroidLink={android_download_link} AppleLink={apple_download_link}/>

    </>
  );

};



export default MediaComponent