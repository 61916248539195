import React , {  useState  }  from 'react';
import { useForm } from "react-hook-form";
import APIService from "../../api.service";
import messageSent from '../../assets/sent.png';
import Modal from 'react-bootstrap/Modal';
import './forms.scss';
import emailError from '../../assets/emailerror.png';

function FormComponent(){
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsError, setError] = React.useState(false);
  const [ErrorMsg, setErrorMsg] = React.useState(false);


  function openModal() { setIsOpen(true) }
  function closeModal() { setIsOpen(false); }
  function closeErrModal() { setError(false); }

  const { register, formState: { errors }, handleSubmit } = useForm();
  const onSubmit = data => {
    // data = { ...data, city: 'null'};
    // data = { ...data, request_type: 'null'};

    APIService.contactus(data).then(response => {   
      setIsOpen(true)
     }).catch(e => {
      setError(true)
      setErrorMsg(e.response.data.message)
     });
  };
  return (
    <>
        <Modal show={modalIsOpen} onHide={closeModal}>
          <Modal.Body>
              <img src={messageSent} className='messageSent'/>
              <h3 className='popupmessage text-center mt-4 mb-4'>Your message has been send successfully</h3>
              <button onClick={closeModal} className='d-block mx-auto btn btn-danger'>Back to Site</button>
          </Modal.Body>
        </Modal>
        <Modal show={modalIsError} onHide={closeErrModal}>
          <Modal.Body>
              <img src={emailError} className='messageSent'/>
              <h3 className='popupmessage text-center mt-4 mb-4'>Something Went Wrong</h3>
              <p className='text-center'>{ErrorMsg}</p>
              <button onClick={closeErrModal} className='d-block mx-auto btn btn-danger'>Back to Site</button>
          </Modal.Body>
        </Modal>
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* onSubmit={e => e.preventDefault()} */ /* ⚠ */}
                <div className="form-group">
                  <label>Full Name</label>
                  <input className="form-control" {...register("name", { required: 'Name is required', maxLength: { value: 20, message: "Maximum 20 character", } , pattern: { value: /^[A-Za-z ]+$/i, message: "Please Enter Valid Name"}  }) } type="text" placeholder="Enter your full Name" />
                  <small id="emailHelp" className="errormsg">{errors.name? errors.name.message : null}</small>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label>Phone</label>

                      <input className="form-control" {...register("phone", { required: 'Phone No is required' , pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/, message: "⚠ Please Enter Valid Phone No"} }) } type="tel" placeholder="Enter your Phone No" />
                      <small id="emailHelp" className="errormsg">{errors.phone? errors.phone.message : null}</small>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                    <label>Email</label>

                      <input className="form-control" {...register("email", { required: 'Email is required' , pattern: { value:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "Please Enter Valid Email"}  }) } type="text" placeholder="Enter your Email" />
                      <small id="emailHelp" className="errormsg">{errors.email? errors.email.message : null}</small>
                    </div>
                  </div>
                </div>
                
                
                <div className="form-group">
                  <label>Interest</label>

                  <input className="form-control" {...register("interest", { required: 'This Field is Required' }) } type="text" placeholder="Interest" />
                  <small id="emailHelp" className="errormsg">{errors.interest? errors.interest.message : null}</small>
                </div>
                <div className="form-group">
                <label>Message</label>

                  <textarea className="form-control" {...register("message", { required: 'This field is required' }) } ></textarea>
                  <small id="emailHelp" className="errormsg">{errors.message? errors.message.message : null}</small>
                </div>
                <input type="submit"  className='formSubmitButton'/>
             </form>
        </div>
    </>
  );

};



export default FormComponent;