

import React , { useEffect , useState  }  from 'react';
import APIService from "../../api.service";
import GameComponent from './gameList';
import './games.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import DownloadComponent from '../download/download'

function GamePageComponent(props) {

  
  const dataa = props.setting.data;
  let downloadLink = '';
  let android_download_link = '';
  let apple_download_link = '';

  if(dataa ==  null){
      console.log("null Data")
  }else{
      android_download_link = dataa[0].android_download_link ;
      apple_download_link = dataa[0].ios_download_link ;
      downloadLink = dataa[0].downloadLink ;

  }




  const [error, setError] = useState(null);
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    APIService.getPage('games').then(response => {
      setPageData(response.data.data);
      setError(null)
    }).catch(e => {
      setError(e.message)
    });
  }, []);

   

  return (
    <>
    <HelmetProvider>
    {pageData ?  <Helmet>
        <meta charSet="utf-8" />
        <title>{pageData.meta_title}</title>
        <meta name="description" content={pageData.meta_description} />
        <meta property="og:title" content={pageData.og_title} />
        <meta property="og:site_name" content={pageData.og_title} />
        <meta property="og:type" content={pageData.og_type} />
        <meta property="og:image" content={pageData.image} />
        <meta property="og:description" content={pageData.og_description} />
        <meta property="og:url" content={pageData.og_url} />
        <link rel="canonical" href={pageData.canonical_link } />
        <meta property="keywords" content={pageData.meta_title } />

      </Helmet> : ""}
    </HelmetProvider>
    <div className='page gamePage'>  
      <div className="container">
        <div className="row">
          <GameComponent />
        </div>
      </div>
    </div>

    <DownloadComponent  AndroidLink={android_download_link} AppleLink={apple_download_link}/>

    </>
  );
}

export default GamePageComponent;
