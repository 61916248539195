import React , { useEffect , useState  }  from 'react';
import APIService from "../../api.service";
import notfoundImage from '../../assets/404.png';
import aboutone from '../../assets/images/Banners/about-us-01.jpeg';
import abouttwo from '../../assets/images/Banners/about-us-02.jpeg'

import { Helmet, HelmetProvider } from 'react-helmet-async';
import './sweep-rules.scss';
 
function SweepRulesComponent(){
  const [error, setError] = useState(null);
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    APIService.getPage('sweeprules').then(response => {
      setPageData(response.data.data);
      setError(null)
      // console.log(response.status)
    }).catch(e => {
      setError(e.message)
    });
  }, []);

  if(!pageData){
    return(
      <div className="page termsPage">
        <div className="container">
          <div className="row">
            <div className='loadingspinner'></div>
          </div>
        </div>
      </div>

    )
  }

  return (
    <>
        <HelmetProvider>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageData.meta_title}</title>
        <meta name="description" content={pageData.meta_description} />
        <meta property="og:title" content={pageData.og_title} />
        <meta property="og:site_name" content={pageData.og_title} />
        <meta property="og:type" content={pageData.og_type} />
        <meta property="og:image" content={pageData.image} />
        <meta property="og:description" content={pageData.og_description} />
        <meta property="og:url" content={pageData.og_url} />
        <link rel="canonical" href={pageData.canonical_link } />
        <meta property="keywords" content={pageData.meta_title } />

      </Helmet>
      </HelmetProvider>
      <div className="page termsPage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
               <h1>{pageData !== null ? pageData.name : ""}</h1>
              
              <div dangerouslySetInnerHTML={{__html: pageData !== null ? pageData.description : ""}}></div>


            </div>
          </div>
          
        </div>
      </div>
     
    </>
  );
};



export default SweepRulesComponent;