import React , { useEffect , useState  }  from 'react';
import APIService from "../../api.service";
import notfoundImage from '../../assets/404.png'

import { Helmet, HelmetProvider } from 'react-helmet-async';
import './promotions.scss';
import DownloadComponent from '../download/download'

function PromotionsComponent(props){

  const dataa = props.setting.data;
    let downloadLink = '';
    let android_download_link = '';
    let apple_download_link = '';


    if(dataa ==  null){
      console.log("null Data")
  }else{
      android_download_link = dataa[0].android_download_link ;
      apple_download_link = dataa[0].ios_download_link ;
      downloadLink = dataa[0].downloadLink ;

  }


  const [data, setData] = useState([]);
  const [pageData, setPageData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    APIService.getPromotions(0)
    .then(response => {
        setData(response.data.data);
    })
    .catch(e => {
        console.log(e);
    });


    APIService.getPage('promotions').then(response => {
        setPageData(response.data.data);
        setError(null)
      }).catch(e => {
        setError(e.message)
      });
  }, []);

  
  return (
    <>
      <HelmetProvider>
      {pageData ?  <Helmet>
        <meta charSet="utf-8" />
        <title>{pageData.meta_title}</title>
        <meta name="description" content={pageData.meta_description} />
        <meta property="og:title" content={pageData.og_title} />
        <meta property="og:site_name" content={pageData.og_title} />
        <meta property="og:type" content={pageData.og_type} />
        <meta property="og:image" content={pageData.image} />
        <meta property="og:description" content={pageData.og_description} />
        <meta property="og:url" content={pageData.og_url} />
        <link rel="canonical" href={pageData.canonical_link } />
        <meta property="keywords" content={pageData.meta_title } />

      </Helmet> : ""}
      </HelmetProvider>

      <div className="homepagepromotion">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <h2 className='heading'>Promotions</h2>
                        <p className='description'>You are a born player! You need lightning deals and offers to make your online social gaming straightforward.</p>
                    </div>
                </div>
                <div className="row">
                {data.map((item, i) => (
                    <div key={i} className="col-lg-4 col-md-12 col-12 col-12">
                        <div className="promotionCard">
                            {item.image !== null ? (<img src={`${process.env.REACT_APP_FILE_BASE_URL}/public${item.image.key}`} alt={item.slug} className='w-100'  /> ) : ""}                      
                            <div className='promotionCaption'>
                                <h5 className='promotionTitle'>{item.name}</h5>
                                <button className='promotionBlue'>Get Bonus</button>
                            </div>
                        </div>
                    </div>
                    
                ))}
                </div>
            </div>
        </div>

        <DownloadComponent  AndroidLink={android_download_link} AppleLink={apple_download_link}/>

    </>
  );

};



export default PromotionsComponent