import http from "./http-common";

class TutorialDataService {
  getContent(path){
    return http.get(`/${path}`);
  }
  getAll(category, itemNo) {
    if(category){
      return http.get(`/game-${category}/?itemNo=${itemNo}`);
    }else{
      return http.get(`/game/`);
    }
  }


  getData(category, itemNo) {
    if(category){
      return http.get(`/${category}/?itemNo=${itemNo}`);
    }else{
      return http.get(`/game/`);
    }
  }

  get(id) {
    return http.get(`/game/${id}`);
  }
  
  getHeaderBg(){
    return http.get(`/header-background/`);
  }
  getScreenshot(id){
    return http.get(`/game-screenshot/?game_id=${id}`);
  }
  
  getGameType() {
      return http.get(`/game-type/`);
  }
  gameType(category, itemType) {
    if(itemType && category){
      return http.get(`/game-${itemType}/?type=${category}`);
    }else if(category){
      return http.get(`/game/?type=${category}`);
    }else if(itemType){
      return http.get(`/game-${itemType}`);

    }else{
      return http.get(`/game/`);
    }
  }
  getSearch(string){
    return http.get(`/search/?search=${string}`);
  }
  create(data) {
    return http.post("/contact-us/", data);
  };
  register(data) {
    return http.post("/users/", data);
  };
  subscribeData(data) {
    return http.post("/newsletter/", data);
  };
  brandType(typeid) {
    if(typeid){
      return http.get(`game?type=${typeid}`);
    
    }else{
      return http.get(`/game/`);
    }
  }

}

export default new TutorialDataService();