import React , { useEffect , useState  }  from 'react';
 import APIService from "../../api.service";

function PromotionHomeComponent(){
  const [data, setData] = useState([]);

  useEffect(() => {
    APIService.getPromotions(3)
    .then(response => {
        setData(response.data.data);
    })
    .catch(e => {
        console.log(e);
    });
  }, []);
  return (
    <>
    <div className="homepagepromotion">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <h2 className='heading'>Promotions</h2>
                        <p className='description'>You are a born player! You need lightning deals and offers to make your online social gaming straightforward.</p>
                    </div>
                </div>
                <div className="row">
                {data.map((item, i) => (
                    <div  key={i} className="col-lg-4 col-md-12 col-12 col-12">
                        <div className="promotionCard">
                            {item.image !== null ? (<img src={`${process.env.REACT_APP_FILE_BASE_URL}/public${item.image.key}`} alt={item.slug} className='w-100'  /> ) : ""}                      
                            <div className='promotionCaption'>
                                <h5 className='promotionTitle'>{item.name}</h5>
                                <button className='promotionBlue'>Get Bonus</button>
                            </div>
                        </div>
                    </div>
                    
                ))}
                </div>
            </div>
        </div>
    </>
  );

};



export default PromotionHomeComponent;